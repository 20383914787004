import React, {useContext} from 'react'
import { APP_NAME } from 'configs/AppConfig'
import { connect } from 'react-redux'
import { CONSOLE_VERSION } from '../../version'
import {WI_CLIENT_VERSION, WIRELESS_INSIGHTS_VERSION} from '../../constants/ConstantsWI'
import { AuthContext } from '../../auth/AuthProvider'
import { getWiPermission } from '../../auth/FirebaseAuth'

function Footer({ windowPaddingLeft }) {
  const context = useContext(AuthContext)
  // const wiPermission = getWiPermission(context?.currentUser) // use wiContext next time instead context
  return (
    <footer className={`footer ${windowPaddingLeft}`}>
      <span>
        Copyright &copy; {`${new Date().getFullYear()}`}{' '}
        <span className="font-weight-semibold">{`${APP_NAME}`}</span> All rights reserved.
      </span>
      <div>zDNA Cloud: v{CONSOLE_VERSION}</div>
      <div>Wireless Insights: v{WIRELESS_INSIGHTS_VERSION}</div>
      <div>WI Client: v{WI_CLIENT_VERSION}</div>
      <div>
        <a
          className="text-gray"
          href="https://www.zebra.com/us/en/about-zebra/company-information/legal/terms-of-use.html"
          target="_blank"
        >
          Terms & Conditions
        </a>
        <span className="mx-2 text-muted"> | </span>
        <a
          className="text-gray"
          href="https://www.zebra.com/us/en/about-zebra/company-information/legal/privacy-statement.html"
          target="_blank"
        >
          Privacy Policy
        </a>
      </div>
    </footer>
  )
}

const mapStateToProps = ({ theme }) => {
  const { windowPaddingLeft } = theme
  return { windowPaddingLeft }
}

export default connect(mapStateToProps)(Footer)
