import React, { useContext } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import AppLayout from 'layouts/app-layout'
import AuthLayout from 'layouts/auth-layout'
import UserLayout from 'layouts/user-layout'
import AppLocale from 'lang'
import { IntlProvider } from 'react-intl'
import { ConfigProvider } from 'antd'
import { AuthContext } from '../auth/AuthProvider'
import { GA_TRACK_ID } from 'constants/Constant'
import { useWirelesIinsightStateContext } from './wi-views/utils/contextProvider/wiContextPrivder'
import ReactGA from 'react-ga4'

if (GA_TRACK_ID) ReactGA.initialize(GA_TRACK_ID)

function RouteInterceptor({ children, isAuthenticated, location, ...rest }) {
  React.useEffect(() => {
    //console.log("window.location.pathname + window.location.search ===",window.location.pathname + window.location.search)
    if (GA_TRACK_ID) ReactGA.send(window.location.pathname + window.location.search)
  }, [location.pathname])
  // console.log('RouteInterceptor ' + JSON.stringify(isAuthenticated))
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated != null ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/auth',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export const Views = (props) => {
  const { locale, location } = props
  const currentAppLocale = AppLocale[locale]
  const context = useContext(AuthContext)

  // check if it is wirelessinsights then set all to default and update the time
  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/app/home" />
          </Route>
          <Route path="/auth">
            <AuthLayout />
          </Route>
          <Route path="/legacy">
            <AuthLayout />
          </Route>
          <RouteInterceptor path="/app" isAuthenticated={context?.currentUser}>
            <AppLayout location={location} />
          </RouteInterceptor>
          <RouteInterceptor path="/user" isAuthenticated={context?.currentUser}>
            <UserLayout location={location} />
          </RouteInterceptor>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale } = theme
  const { token } = auth
  return { locale, token }
}

export default withRouter(connect(mapStateToProps)(Views))