import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './redux/store'
import { TranslationProvider } from 'hooks/useTranslation'
import App from './App'
import './index.scss'
import './ekbOverloadAntd.css'
import './rxLoggerOverloadAntd.css'
import * as serviceWorker from './serviceWorker'

let enableLogs = false

const hostname = window.location.hostname

enableLogs = [
  'localhost',
  'qa-dnacloud.zebra.com',
  'dev-dnacloud.zebra.com',
  'emc-mdnacloud-demo-t',
  'dev-cit-dnacloud.zebra.com',
  'emc-zdnacloud-sysarch-d',
  'int-dnacloud.zebra.com',
].some((item) => hostname.includes(item))

if (!enableLogs) {
  const noop = () => { }
    ;[
      'assert',
      'clear',
      'count',
      'debug',
      'dir',
      'dirxml',
      'error',
      'exception',
      'group',
      'groupCollapsed',
      'groupEnd',
      'info',
      'log',
      'markTimeline',
      'profile',
      'profileEnd',
      'table',
      'time',
      'timeEnd',
      'timeline',
      'timelineEnd',
      'timeStamp',
      'trace',
      'warn',
    ].forEach((method) => {
      window.console[method] = noop
    })
}

const RootEl = (
  <TranslationProvider lang="en">
    <Provider store={store}>
      <App />
    </Provider>
  </TranslationProvider>
)

ReactDOM.render(RootEl, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
