import React, { createContext, useState, useEffect, useLayoutEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { updateWindowSizesOnResize } from 'redux/actions/Theme'
import Views from './views'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './ErrorFallback'
import { AuthProvider } from './auth/AuthProvider'
import { WirelesIinsightProvider } from './views/wi-views/utils/contextProvider/wiContextPrivder'
import IdleTimerContainer from 'components/layout-components/IdleTimerContainer'
import { GoogleDriveProvider } from 'auth/GoogleDriveProvider'

export const LicenseStateContext = createContext({})
export const DeviceUsersStateContext = createContext({})
let catchCrashIssue = false
const hostname = window.location.hostname
catchCrashIssue = ['localhost'].some((item) => hostname.includes(item))

function App({ updateWindowSizes }) {
  const [licenseState, setLicenseState] = useState(false)
  const [deviceUsersState, setDeviceUsersState] = useState(false)
  const [isLicenseAvailable, setIsLicenseAvailable] = useState(false)

  useEffect(() => {
    updateSize()
  }, [updateSize])

  useLayoutEffect(() => {
    const debouncedUpdateSize = debounce(updateSize, 200)
    window.addEventListener('resize', debouncedUpdateSize)
    return () => window.removeEventListener('resize', debouncedUpdateSize)
  }, [updateSize])

  const debounce = (fn, delay) => {
    let timerId
    return (...args) => {
      clearTimeout(timerId)
      timerId = setTimeout(fn, delay, [...args])
    }
  }

  function updateSize() {
    updateWindowSizes(window.innerWidth < 992)
  }

  return (
    <AuthProvider>
      <WirelesIinsightProvider>
        <LicenseStateContext.Provider
          value={{ licenseState, setLicenseState, isLicenseAvailable, setIsLicenseAvailable }}
        >
          <DeviceUsersStateContext.Provider value={{ deviceUsersState, setDeviceUsersState }}>
            <GoogleDriveProvider>
              <Router>
                <Switch>
                  <Route path="/" component={Views} />
                </Switch>
              </Router>
            </GoogleDriveProvider>
          </DeviceUsersStateContext.Provider>
        </LicenseStateContext.Provider>
      </WirelesIinsightProvider>
    </AuthProvider>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateWindowSizes: (windowSize) => dispatch(updateWindowSizesOnResize(windowSize)),
  }
}

const AppWithProps = connect(null, mapDispatchToProps)(App)

/* react-error-boundary -> Use to catch the erro occured in underline Components */
function AppWithErrorBoundry() {
  const [explode, setExplode] = React.useState(false)
  return (
    <AuthProvider>
      <GoogleDriveProvider>
        <IdleTimerContainer>
          <div className="App">
            {!catchCrashIssue ? (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => setExplode(false)}
                resetKeys={[explode]}
              >
                <AppWithProps />
              </ErrorBoundary>
            ) : (
              <AppWithProps />
            )}
          </div>
        </IdleTimerContainer>
      </GoogleDriveProvider>
    </AuthProvider>
  )
}

export default AppWithErrorBoundry
